<template>
  <div class="revista-bussola">
    <h1>Revista "A Bússola"</h1>
    <div class="p-fluid grid mb-4">
      <div class="col-12 sm:col-6">
        <span class="title mb-2 block">{{ $t("title") }}:</span>
        <InputText v-model="title" type="text" />
      </div>
      <div class="col-12 sm:col-6">
        <span class="title mb-2 block">{{ $t("year") }}:</span>
        <InputText v-model="year" type="text" />
      </div>
    </div>
    <div class="grid">
      <template v-for="bussola in filteredBussolas" :key="bussola.id">
        <ShowBussola :bussola="bussola" />
      </template>
    </div>
  </div>
</template>

<script>
import ShowBussola from "./ShowBussola.vue";

export default {
  components: { ShowBussola },
  data() {
    return {
      bussolas: [],
      title: null,
      year: null,
    };
  },
  computed: {
    filteredBussolas() {
      this.year;
      this.title;
      return this.bussolas.filter((bussola) => {
        const title = bussola.title.rendered;
        const yearMatch = title.match(/(\d+)$/);
        return (
          (!this.title ||
            title.toLowerCase().includes(this.title.toLowerCase())) &&
          (!this.year || !yearMatch || yearMatch[0] == this.year)
        );
      });
    },
  },
  created() {
    this.getBussolas();
  },
  methods: {
    getBussolas() {
      let params = { dataType: "client", isActive: true };
      this.$root
        .apiGet(this.$root.api.externalApiBussolaPosts, { params })
        .then((response) => {
          this.bussolas = response.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>

<template>
  <Skeleton v-if="!blobUrl" size="3rem" class="mr-2"></Skeleton>
  <div
    v-else
    class="img-user mr-2"
    :style="{
      backgroundImage: `url(${blobUrl})`,
      width: width + 'px',
      height: height + 'px',
    }"
    @error="$root.setDefaultImage"
  ></div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  components: { Skeleton },
  props: {
    url: { type: String, default: null },
    imgClass: { type: String, default: "" },
    imgAlt: { type: String, default: "" },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
  },
  data() {
    return {
      blobUrl: null,
    };
  },
  mounted() {
    this.loadFile();
  },
  methods: {
    loadFile() {
      const regex = /^(\/|)?(tmp|dir|imagens)\//;
      if (
        !this.url ||
        this.url.includes(window.location.hostname) ||
        this.url.startsWith("/assets")
      ) {
        this.blobUrl = this.$root.images.defaultPicture;
      } else if (regex.test(this.url)) {
        this.blobUrl = this.$root.getUrlFromFilePath(this.url);
      } else {
        this.blobUrl = null;
        const filename = this.url.split("/").pop().split(".")[0];
        this.$root
          .apiGetFile(this.$root.getApiFileName(filename))
          .then((response) => {
            if (response) {
              this.blobUrl = URL.createObjectURL(response.data);
            }
          });
      }
    },
  },
};
</script>

<template>
  <div
    v-if="bussola && thumbnail"
    :class="{
      'col-12 md:col-4 sm:col-6': !sidebar,
    }"
  >
    <div class="pb-0 flex flex-wrap card-sidebar">
      <h2 class="title w-full pb-2">"A Bússola"</h2>
      <div class="w-full flex card-content">
        <a v-if="bussola.id" href="#" @click="openInNewTab">
          <img
            :src="thumbnail"
            class="pr-5 pb-0"
            style="height: 200px; display: block"
          />
        </a>
        <div class="flex flex-column justify-content-between w-full">
          <div>
            <div class="">{{ $t("edition_number") }}{{ number }}</div>
            <div class="mt-2 mb-2 desc text-700">
              {{ description }}
            </div>
          </div>
          <div
            class="
              see_more
              cursor-pointer
              py-1
              px-4
              text-xs
              ml-auto
              text-center
            "
            @click="openInNewTab"
          >
            {{ $t("see_more") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    bussola: { type: Object, default: null },
    sidebar: { type: Boolean, default: false },
  },
  setup() {
    const thumbnail = ref("");
    const description = ref("");
    return { thumbnail, description };
  },
  computed: {
    number() {
      if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
        const numberMatch = this.bussola.title.rendered.match(/(\d+)/);
        return numberMatch ? numberMatch[1] : null;
      }
      return null;
    },
    publishDate() {
      if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
        const dateMatch = this.bussola.title.rendered.match(/(\w+ \d+)$/);
        return dateMatch ? dateMatch[1] : null;
      }
      return null;
    },
  },
  mounted() {
    if (
      this.bussola &&
      this.bussola.x_metadata &&
      this.bussola.x_metadata._elementor_data &&
      !this.bussola.x_featured_media_large
    ) {
      const jsonObject = JSON.parse(this.bussola.x_metadata._elementor_data);
      if (!jsonObject) {
        return null;
      }
      const index = jsonObject.length > 1 ? 1 : 0;
      if (
        jsonObject &&
        jsonObject[index] &&
        jsonObject[index].elements &&
        jsonObject[index].elements[0] &&
        jsonObject[index].elements[0].elements &&
        jsonObject[index].elements[0].elements[0] &&
        jsonObject[index].elements[0].elements[0].settings
      ) {
        let shortcode =
          jsonObject[index].elements[0].elements[0].settings.shortcode ||
          jsonObject[index].elements[0].elements[0].settings.editor ||
          jsonObject[index].elements[0].elements[0].settings.html;
        if (shortcode) {
          const match = shortcode.match(/id=\\?"(\d+)\\?"/);
          if (match && match[1]) {
            const id = match[1];
            this.thumbnail = this.$root.getApiExternalBussolaThumbnail(id);
          }
        }
      }
    } else if (this.bussola && this.bussola.x_featured_media_large) {
      this.thumbnail = this.bussola.x_featured_media_large;
    }
    if (this.bussola.descricao) {
      this.description = this.bussola.descricao;
    }
  },
  methods: {
    openInNewTab() {
      window.open(this.bussola.link, "_blank");
    },
  },
};
</script>
